import { leafletLayer } from './../../../_types/types';
import { CountryService } from 'src/_services/api/country.service';
import { SelectorService } from 'src/_services/selector.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
declare var L: any;
import { LayerGroup, Layer, FeatureGroup } from 'leaflet';
import { reducers } from 'src/_contants/store.reducers';
import { setOpenWeather, setUserLoaction } from 'src/_store/map/map.actions';
import { MapHelperService } from 'src/_services/map-helper.service';

export class LeafletHelper {
    map: any
    mapLayers: Map<leafletLayer, Layer>
    mapTile: LayerGroup = L.layerGroup([])
    layerControl: any;
    geojsonMarkerOptions = {
      radius: 8,
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    };
    constructor(
                public translate: TranslateService,
                public store: Store<typeof reducers>, 
                public selectorService: SelectorService,
                public countryService: CountryService,
                public mapHelper: MapHelperService
                ){
    this.translate = translate
    this.selectorService = selectorService
    this.countryService = countryService
    this.mapHelper = mapHelper

    this.mapLayers = new Map([
        ['jool', this.mapBox],
        ['googleSatelite', this.googleSatelite],
        ['openStreet', this.openStreetMap],
        ['worldImage', this.worldImage]
    ])
}

public mapSetting(bound: any){
 return {
    bound: bound,
    "attribution": "&copy; IGN",
    "minZoom": 0,
    "maxZoom": 24,
    "tms": false,
    "opacity": 1,
    detectRetina: true
  }
}
  
  public get _map() {
    return this.map
  }
  

  public get mapBox() {
    // tslint:disable-next-line: max-line-length
    return L.tileLayer('https://api.mapbox.com/styles/v1/naf94/ckhhopnxn2go319nu282fi710/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibmFmOTQiLCJhIjoiY2s5Y21nMzNjMDA3cTNlczUxNTU4Y2s0YiJ9.KduEUSPHqfHmsjg5EQAKpw', {
      // tslint:disable-next-line: max-line-length
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 24,
      minZoom: 0,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'your.mapbox.access.token'
    });
  }

  public get openStreetMap() {
    let zoom = {
      minZoom: 0,
      maxZoom: 24
    };
    return L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { minZoom: zoom.minZoom, maxZoom: zoom.maxZoom });
  }

  public get worldImage() {
    let zoom = {
      minZoom: 0,
      maxZoom: 24
    };
    // tslint:disable-next-line: max-line-length
    return L.tileLayer('//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      { minZoom: zoom.minZoom, maxZoom: zoom.maxZoom, attribution: '&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community' });
  }

  public get googleSatelite() {
    let zoom = {
      minZoom: 0,
      maxZoom: 20
    };
    // tslint:disable-next-line: max-line-length
    return L.tileLayer('https://api.mapbox.com/styles/v1/naf94/ckzwthza7000014rvih7lx5gm/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibmFmOTQiLCJhIjoiY2s5Y21nMzNjMDA3cTNlczUxNTU4Y2s0YiJ9.KduEUSPHqfHmsjg5EQAKpw',
      {
        minZoom: zoom.minZoom,
        maxZoom: zoom.maxZoom,
        attribution: '&copy; Esri &mdash; Sources: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community'
      });
  }

  switchMapLayer(value: leafletLayer) {
    if(this.mapTile){
      this.mapTile.clearLayers()
    }
    if(value !== 'noLayer'){
      this.mapTile.addLayer(this.mapLayers.get(value) as Layer)
      this.map.addLayer(this.mapTile)
    }
    
    /*
    switch (value) {
      case 'jool_int':
        this.map.removeLayer(this.openStreetMap)
        this.map.removeLayer(this.worldImage)
        this.map.removeLayer(this.googleSatelite)
        this.map.addLayer(this.mapBox)
        break;
      case 'open_street':
        this.map.removeLayer(this.mapBox)
        this.map.removeLayer(this.worldImage)
        this.map.removeLayer(this.googleSatelite)
        this.map.addLayer(this.openStreetMap)
        break;
      case 'img_monde':
        this.map.removeLayer(this.mapBox)
        this.map.removeLayer(this.openStreetMap)
        this.map.removeLayer(this.googleSatelite)
        this.map.addLayer(this.worldImage)
        break;
      case 'google_satelite':
        this.mapTile.clearLayers()
        this.mapTile.addLayer(this.mapLayers.get(value))
        this.map.addLayer(this.googleSatelite)
        case 'ortho':
        this.map.removeLayer(this.globalDsmLayer)
        this.map.removeLayer(this.globalDtmLayer)
        this.map.removeLayer(this.globalPlantLayer)
        this.map.addLayer(this.globalOrthoLayer)
        break;
      case 'dtm':
        this.map.removeLayer(this.globalDsmLayer)
        this.map.removeLayer(this.globalOrthoLayer)
        this.map.removeLayer(this.globalPlantLayer)
        this.map.addLayer(this.globalDtmLayer)
        break;
      case 'dsm':
        this.map.removeLayer(this.globalDtmLayer)
        this.map.removeLayer(this.globalOrthoLayer)
        this.map.removeLayer(this.globalPlantLayer)
        this.map.addLayer(this.globalDsmLayer)
        break
      case 'health':
        this.map.removeLayer(this.globalDtmLayer)
        this.map.removeLayer(this.globalOrthoLayer)
        this.map.removeLayer(this.globalDsmLayer)
        this.map.addLayer(this.globalPlantLayer) */
  }
  

  public get baseLayer() {
    return {
      "Open Street Map": this.openStreetMap,
      "Image du monde": this.worldImage,
      "Jool": this.mapBox,
      "Google satelite": this.googleSatelite,

     /*  "ortho": this.globalOrthoLayer,
      "plantHealth": this.globalPlantLayer,
      "dtm": this.globalDtmLayer,
      "dsm": this.globalDsmLayer,
      "plot": this.plotLayer,
      "plotCentroid": this.plotCentroidLayer,
      "Zone protected": this.zoneProtegeLayer,
      "Notations": this.notingGeometryLayer */
    }
  }

  public getMyPosition(): number[] {
    let geo: any;

    if (navigator.geolocation) {
      // L'API est disponible
      const maPosition = (position: any) => {
      
        geo = [position.coords.latitude, position.coords.longitude];
       
        this.store.dispatch(setUserLoaction({geo: geo}))
      }

      
      navigator.geolocation.getCurrentPosition(
        maPosition,
        errorCallback,
        {
          enableHighAccuracy: true,
          timeout: 10000, // 10s
          maximumAge: 0
        }
      );

    } else {
    }

  
    // La fonction d'échec teste l'erreur contenue dans "error"
    function errorCallback(error: any) {
      //Un message d'erreur est affiché selon le type d'erreur
      switch (error.code) {
        case error.PERMISSION_DENIED:
          break;
        case error.POSITION_UNAVAILABLE:
          break;
        case error.TIMEOUT:
          break;
        case error.UNKNOW_ERROR:
          break;
      }
    }

    
    
    return geo;
  }

  public get MesurControlOptions() {
    const unit = this.translate.currentLang === 'fr' ? 'kilometers' : 'miles';
    const areaUnit = this.translate.currentLang === 'fr' ? 'hectares' : 'acres';
    const options = {
      position: 'topleft',
      primaryLengthUnit: unit, secondaryLengthUnit: undefined,
      primaryAreaUnit: areaUnit, secondaryAreaUnit: undefined,
      activeColor: '#ABE67E',
      completedColor: '#C8F2BE',
      captureZIndex: 10000,
    }
    return options
  }

  public drawOption(editableLayers: any) {
    return {
      position: 'topright',
      draw: {
        circlemarker: false,
      },
      edit: {
        featureGroup: editableLayers, //REQUIRED!!
        remove: true,
        edit: true
      }
    };
  }
  
  public get LineMeasureOptions() {
    return {
      position: 'topleft',            // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
      unit: 'metres',                 // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
      clearMeasurementsOnStop: true,  // Clear all the measurements when the control is unselected
      showBearings: false,            // Whether bearings are displayed within the tooltips
      bearingTextIn: 'In',            // language dependend label for inbound bearings
      bearingTextOut: 'Out',          // language dependend label for outbound bearings
      tooltipTextFinish: 'Click to <b>finish line</b><br>',
      tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
      tooltipTextMove: 'Click and drag to <b>move point</b><br>',
      tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
      tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
      // language dependend labels for point's tooltips
      measureControlTitleOn: this.translate.instant('map.measure_distances'),   // Title for the control going to be switched on
      measureControlTitleOff: 'Turn off PolylineMeasure', // Title for the control going to be switched off
      measureControlLabel: '&#8614;', // Label of the Measure control (maybe a unicode symbol)
      measureControlClasses: [],      // Classes to apply to the Measure control
      showClearControl: true,        // Show a control to clear all the measurements
      clearControlTitle: 'Clear Measurements', // Title text to show on the clear measurements control button
      clearControlLabel: '&times',    // Label of the Clear control (maybe a unicode symbol)
      clearControlClasses: [],        // Classes to apply to clear control button
      showUnitControl: false,         // Show a control to change the units of measurements
      distanceShowSameUnit: false,    // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
      unitControlTitle: {             // Title texts to show on the Unit Control button
        text: 'Change Units',
        metres: 'metres',
        landmiles: 'land miles',
        nauticalmiles: 'nautical miles'
      },
      unitControlLabel: {             // Unit symbols to show in the Unit Control button and measurement labels
        metres: 'm',
        kilometres: 'km',
        feet: 'ft',
        landmiles: 'mi',
        nauticalmiles: 'nm'
      },
      tempLine: {                     // Styling settings for the temporary dashed line
        color: '#28a745',              // Dashed line color
        weight: 2                   // Dashed line weight
      },
      fixedLine: {                    // Styling for the solid line
        color: '#28a745',              // Solid line color
        weight: 2                   // Solid line weight
      },
      startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
        color: '#000',              // Color of the border of the circle
        weight: 1,                  // Weight of the circle
        fillColor: '#0f0',          // Fill color of the circle
        fillOpacity: 1,             // Fill opacity of the circle
        radius: 3                   // Radius of the circle
      },
      intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
        color: '#000',              // Color of the border of the circle
        weight: 1,                  // Weight of the circle
        fillColor: '#ff0',          // Fill color of the circle
        fillOpacity: 1,             // Fill opacity of the circle
        radius: 3                   // Radius of the circle
      },
      currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
        color: '#000',              // Color of the border of the circle
        weight: 1,                  // Weight of the circle
        fillColor: '#f0f',          // Fill color of the circle
        fillOpacity: 1,             // Fill opacity of the circle
        radius: 3                   // Radius of the circle
      },
      endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
        color: '#000',              // Color of the border of the circle
        weight: 1,                  // Weight of the circle
        fillColor: '#f00',          // Fill color of the circle
        fillOpacity: 1,             // Fill opacity of the circle
        radius: 3                   // Radius of the circle
      },
    }
  }

  

   initMap(Maptype: leafletLayer, editableLayers: FeatureGroup) {
    this.map = L.map('map', {
      fullscreenControl: true,
	    fullscreenControlOptions: {
		  position: 'topleft'
	    },
      tap: false,
      zoom: 7,
      center: [7.919108, -5.655453],
      scrollWheelZoom: true,
      minZoom: 0,
      maxZoom: 24,
      layers: []
    });

    this.switchMapLayer(Maptype)
    
     this.map.addControl(new L.Control.Compass({autoActive:true}));
    this.map.addControl(new L.control.mousePosition(
      {
        position: 'bottomright',
      }
    ));
    this.map.addControl(new L.control.scale({
      position: 'bottomright',
    }))
  // this.map.addControl(new L.Control.Fullscreen());
    // this.layerControl = L.control.layers(this.baseLayer).addTo(this.map); 
    // switch entre les différentes type de carte
    // add mesure

    var measureControl = new L.Control.Measure(this.MesurControlOptions);

    measureControl.addTo(this.map);
    
    var drawControl = new L.Control.Draw(this.drawOption(editableLayers));

    this.map.addControl(drawControl);

    this.map.addLayer(editableLayers);


    
    try {
      L.control.polylineMeasure(this.LineMeasureOptions).addTo(this.map);

    } catch (error) {
    }
    //  L.control.measure().addTo(map)

    document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0].innerHTML = "D\u00e9velopp\u00e9 par<a target=\"_blank\" href=\"https://www.weflyagri.com/fr/\"> jool international </a>: +22522463464";

   
    /* this.map.on('zoomend', () => {
      let currZoom = this.map.getZoom()
      if(currZoom <= 19){
          this.clearLayer('contourPointLayer', false)
      }else{
        this.addLayerToMap(this.contourPointLayer)
      }
    }) */

    this.setWeatherControl()
    // this.map.addControl(new L.Control.Fullscreen());
    // const locale = drawLocales('fr')
    // locale.draw.toolbar.buttons.polygon = 'Awesome polygon!'
    // L.drawLocal = locale
    this.getCountry()
  }

 

  getCountry(country: string = 'cote') {
   /*  this.countryService.getCountry(country).valueChanges.subscribe(
      (res: any) => {
        const countryMap = this.mapHelper.drawPCountry(res.data.searchPays.results[0].mpoly)
        this.countryLayers.addLayer(countryMap)
        this.map.addLayer(this.countryLayers)
        this.map.fitBounds(countryMap.getBounds())


      }
    ) */
  }

  // Weather control
  setWeatherControl() {
    L.Control.Watermark = L.Control.extend({ 

      onAdd: (map: any) => {
        let container = L.DomUtil.create('input', 'weather_btn'); 
        container.type = "button";
        container.style.backgroundColor = 'white';
        container.style.backgroundImage = "url(assets/img/Parcelisation-1.svg)";
        container.onclick = () => {
         this.openOrCloseWeather()
        }
        return container; 
      },

      onRemove: (map: any) => {
        // Nothing to do here
      }
    });

    L.control.watermark = (opts: any) => {
      return new L.Control.Watermark(opts);
    }

    L.control.watermark({ position: 'topleft' }).addTo(this.map);
  }

  async openOrCloseWeather() {
    this.store.dispatch(setOpenWeather({ openWeather: true }))
    const weatheStatus = await this.selectorService.weatherState

    if (weatheStatus) {
     // this.clearLayer('zoneProtected', false)
    } else {
      // this.addLayer('zoneProtected')
    }
  }

  centerGeoJson(geoJson:any){
    this.map.fitBounds(geoJson.getBounds())

  }
}

